footer {
  background: #fff;
  border-radius: 0 0 6px 6px;
  box-shadow: inset 0 1px 0 0 #dce1e7, 0 -15px 25px -10px rgba(#000, 0.07);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
  position: sticky;
  margin: -5px 5px 5px;
  bottom: 0;
  z-index: 2;

  &.ongoing {
    background: #dbf3df;
    color: #57a50b;

    .time {
      background: none;
      padding-left: 0;
    }

    button {
      background: none;
      border: 2px solid #57a50b;
      color: #57a50b;

      &:hover {
        background-color: lighten(#dbf3df, 3%);
      }

      &:active {
        background-color: lighten(#dbf3df, 6%);
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin: 0;
    padding: 25px 35px;
  }

  .time {
    background: url(../assets/time.svg) 0 50% no-repeat;
    flex: 1 1;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.6px;
    line-height: 1.3;
    margin-left: 0;
    padding-left: 45px;
    margin-bottom: 20px;
    width: 100%;

    @media (min-width: 768px) {
      font-size: 26px;
      line-height: inherit;
      margin-left: 55px;
      margin-bottom: 0;
      padding-left: 50px;
    }

    .label {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 12px;
      display: block;
      text-transform: uppercase;
    }

    > span {
      @media (min-width: 768px) {
        margin-right: 40px;
      }

      &:first-child {
        flex-grow: 1;
      }
    }
  }

  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    
    button {
      font-size: 14px;
      min-width: 170px;
      margin: 0;
      margin-top: 10px;
      transform: scale(0.9);
    }
  }
}
