.fields {
  @media (min-width: 768px) {
    display: flex;
    margin: 20px -10px;
  }

  margin-top: 20px;

  /* Hiding the spinner */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
  }
}

.gridColumn {
  @media (min-width: 768px) {
    flex: 1;
    margin: 0 10px;
  }
  position: relative;

  input {
    width: 100%;
  }
}

.fieldWrapper {
  position: relative;
}

.gridTitle {
  color: rgba(#45555f, 0.7);
  display: block;
  font: 300 13px/24px "Montserrat";
  text-transform: uppercase;
  white-space: nowrap;
}

.unitLabel {
  color: #93ad93;
  position: absolute;
  right: 15px;
  top: 50%;
}

.errorLabel {
  color: #f26161;
  font-size: 0.8rem;
}
