.root {
  line-height: 1.5;
  text-align: center;

  @media (max-width: 767px) {
    background: #fff;
    box-shadow: 0 -1px 0 0 #e5e9ed;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 998;
    white-space: nowrap;

    > section {
      display: inline-block;
      height: 80px;
      vertical-align: middle;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0;
    width: 80px;
    height: calc(100vh - 25px - 50px);
    position: sticky;
    top: 25px;
  }

  a,
  > section > span {
    background-position: 50% 8px;
    background-size: auto;
    background-repeat: no-repeat;
    color: #45555f;
    display: inline-block;
    hyphens: manual;
    font-size: 10px;
    padding: 38px 10px 0;
    position: relative;
    text-decoration: none;
    white-space: normal;

    @media (max-width: 767px) {
      width: 20vw !important;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: top;
    }

    @media (min-width: 768px) {
      font-size: 13px;
      width: 100%;
      height: 60px;
    }

    + a {
      @media (min-width: 768px) {
        margin-top: 25px;
      }
    }
  }

  :global .active {
    color: #57a50b;

    @media (min-width: 768px) {
      &:before {
        background: #57a50b;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
      }
    }
  }

  :global .routes {
    background-position: 50% 14px;
    background-image: url(./assets/main-menu--routes--inactive-2.svg);
    position: relative;

    &.active {
      background-image: url(./assets/main-menu--routes--active-2.svg);
    }

    &.upcoming {
      background-position: 50% 10px;
      background-image: url(./assets/main-menu--routes--inactive-1.svg);
      position: relative;

      &.active {
        background-image: url(./assets/main-menu--routes--active-1.svg);
      }
    }

    span {
      display: inline-block;
      text-align: center;
      width: 12px;
      height: 12px;
      line-height: 11px;
      color: #fff;
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 700;
      position: absolute;
      left: calc(50% - 15px);
      top: 11px;

      @media (min-width: 768px) {
        top: 11px;
        left: 25.5px;
      }
    }
  }

  :global .map {
    background-image: url(./assets/main-menu--map--inactive.svg);

    &.active {
      background-image: url(./assets/main-menu--map--active.svg);
    }
  }

  :global .day-and-night {
    background-image: url(./assets/main-menu--day-and-night.svg);
  }

  :global .info {
    background-image: url(./assets/main-menu--info.svg);
  }

  :global .info-wrapper {
    padding-top: 20px;
  }

  :global .messages {
    background-image: url(./assets/main-menu--notifications--inactive-2.svg);

    &.active {
      background-image: url(./assets/main-menu--notifications--active-2.svg);
    }
  }

  :global .admin {
    background-image: url(./assets/main-menu--admin--inactive.svg);

    &.active {
      background-image: url(./assets/main-menu--admin--active.svg);
    }
  }

  :global .user {
    background-image: url(./assets/main-menu--account--inactive.svg);

    &.active {
      background-image: url(./assets/main-menu--account--active.svg);
    }
  }

  :global .vehicle {
    background-image: url(./assets/main-menu--truck--inactive.svg);
    background-position: 50% 5px;

    &.active {
      background-image: url(./assets/main-menu--truck--active.svg);
    }
  }

  :global .vehicles {
    background-image: url(./assets/main-menu--trucks--inactive.svg);
    background-position: 50% 5px;

    &.active {
      background-image: url(./assets/main-menu--trucks--active.svg);
    }
  }

  :global .logout {
    background-image: url(../user/assets/logout.svg);
    background-position: 50% 11px;
    background-size: auto;
    background-repeat: no-repeat;
    color: #f26161;
    display: inline-block;
    hyphens: manual;
    font-size: 10px;

    @media (min-width: 768px) {
      font-size: 13px;
      margin-top: 30px;
      width: auto;
    }
  }
}
