.wrapper {
  background: #34404a;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 25px);
  width: 100%;
  // position: absolute;
  // left: 0;
  // top: 0;

  @media (min-width: 768px) {
    padding: 0 10px 10px;
    //top: 25px;
  }

  > header {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 300;
    height: 50px;
    padding: 15px 0;
    position: relative;
    text-align: center;

    @media (min-width: 1024px) {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.45px;
      text-transform: uppercase;
    }

    > a {
      background: url(../../../assets/arrow-left-white.svg) 10px 50% no-repeat;
      color: #fff;
      display: inline-block;
      font: 400 18px/1.77 Roboto;
      letter-spacing: 0;
      padding-left: 40px;
      position: absolute;
      text-decoration: none;
      text-indent: -10000px;
      text-transform: none;
      left: 0;
      top: 10px;

      @media (min-width: 1024px) {
        text-indent: 0;
      }
    }
    > span {
      display: inline-block;
      max-width: calc(100vw - 50px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.loading {
    background: #2479b3;
  }

  &.unloading {
    background: #cc5198;
  }

  &.completed {
    background: #57a50b;

    .time {
      color: #57a50b;
      background: url(../../../assets/complete.svg) 0 50% no-repeat;
      background-size: 30px 30px;
    }
  }

  .root {
    background: #fafbfa;
    border-radius: 6px 6px 0 0;
    flex: 1 1;

    @media (max-width: 767px) {
      margin: 0 5px 5px;
    }
  }
}
